import React from 'react';
import GraphiQL from 'graphiql';
import { fillAllFields } from '../util/fillFields';
import ReactDOM from "react-dom";

/**
 * Custom version of GraphiQL. Contains code adopted from https://github.com/imolorhe/altair.
 * 
 * @license https://github.com/imolorhe/altair
 */
class GraphiQLWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // REQUIRED:
            // `fetcher` must be provided in order for GraphiQL to operate
            fetcher: this.props.fetcher,

            // OPTIONAL PARAMETERS
            // GraphQL artifacts
            query    : this.props.query,
            variables: this.props.variables,
            response : '',

            // GraphQL Schema
            // If `undefined` is provided, an introspection query is executed
            // using the fetcher.
            schema: undefined,

            // Useful to determine which operation to run
            // when there are multiple of them.
            operationName: null,
            storage      : null,
            defaultQuery : null,

            // Custom Event Handlers
            onEditQuery        : this.props.onEditQuery,
            onEditVariables    : this.props.onEditVariables,
            onEditOperationName: this.props.onEditOperationName,

            // GraphiQL automatically fills in leaf nodes when the query
            // does not provide them. Change this if your GraphQL Definitions
            // should behave differently than what's defined here:
            // (https://github.com/graphql/graphiql/blob/master/src/utility/fillLeafs.js#L75)
            getDefaultFieldNames: null
        };
        this.graphiqlRef = React.createRef();
    }

    componentDidMount() {
        let codeMirror = this.graphiqlRef.current.getQueryEditor();
        codeMirror.on('hasCompletion', (cm, event) => {
            this.onHasCompletion(cm, event)
        });
        let extraKeys = codeMirror.getOption("extraKeys");
        extraKeys['Shift-Ctrl-Enter'] = (cm => {
            // console.log("Shift-Ctrl-Enter key pressed.");
            this.onFillFields(cm);
        });
        codeMirror.setOption("extraKeys", extraKeys);
    }

    getQuery() {
        return this.graphiqlRef.current.getQueryEditor().getValue();
    }

    setQuery(newValue) {
        return this.graphiqlRef.current.setState({query: newValue});
    }

    getVariables() {
        return this.graphiqlRef.current.getVariableEditor().getValue();
    }

    setVariables(newValue) {
        return this.graphiqlRef.current.setState({variables: newValue});
    }



    render() {
        return (
            <GraphiQL ref={this.graphiqlRef} {...this.state}/>
        );
    }

    destroyComponent(domElement) {
        ReactDOM.unmountComponentAtNode(domElement);
    }

    onFillFields(cm) {
        const cursor = cm.getCursor();
        const token = cm.getTokenAt(cursor);
        let graphiQl = this.graphiqlRef.current;
        const schema = graphiQl.state.schema;
        console.log("schema = ", schema);
        if (!schema) {
            return;
        }

        const { insertions, result } = fillAllFields(
            graphiQl.state.schema,
            cm.getValue(),
            cursor,
            token,
            {
                maxDepth: 1
            }
        );
        if (result) {
            cm.setValue(result);
        }

        setTimeout(() => {
            cm.setCursor(cursor);
        }, 1);
    }

    onHasCompletion(cm, event) {
        //no-op for now
    }
}

export default GraphiQLWrapper;
