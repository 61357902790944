import queryString from 'query-string';
// import purl from 'purl';
/**
 * Helper to provide options for {@link GraphiQLWrapper}. Code was adopted from GraphiQL's example code.
 */
export default class GraphQLOptions {
    constructor(options) {
        this.options = options || {};
    }

    /**
     * Gets the default options.
     * @returns {{fetcher: (function(*=): Promise<string | never>), query: *, variables: *, operationName: *,
     *     onEditQuery: GraphQLOptions.onEditQuery, onEditVariables: GraphQLOptions.onEditVariables,
     *     onEditOperationName: GraphQLOptions.onEditOperationName}}
     */
    getOptions() {
        return {
            fetcher            : this.graphQLFetcher.bind(this),
            query              : this.options['query'],
            variables          : this.options['variables'],
            operationName      : this.options['operationName'],
            onEditQuery        : this.options['onEditQuery'],
            onEditVariables    : this.options['onEditVariables'],
            onEditOperationName: this.options['onEditOperationName']
        }
    }

    /**
     * @private
     */
    getQueryParams() {
        if (this.params == null) {
            this.params = queryString.parse(window.location.search) || {};
        }
        try {
            this.params.variables =
                JSON.stringify(JSON.parse(parameters.variables), null, 2);
        } catch (e) {
            // Do nothing, we want to display the invalid JSON as a string, rather
            // than present an error.
        }
        return this.params;
    }

    // noinspection JSMethodCanBeStatic
    getContextRoot() {
        const url = purl(window.location);
        let contextRoot = "";
        const pathTokens = url.attr('path').split("/") || [];
        if (pathTokens[2] === "desktop" || pathTokens[2] === "test" ) {
            contextRoot = pathTokens[1];
        }
        return contextRoot;
    }

    /**
     * @private
     * Defines a GraphQL fetcher using the fetch API. You're not required to
     * use fetch, and could instead implement graphQLFetcher however you like,
     * as long as it returns a Promise or Observable.
     */
    graphQLFetcher(graphQLParams) {
        // This example expects a GraphQL server at the path /graphql.
        // Change this to point wherever you host your GraphQL server.
        let url = (this.options || {}).url || "/api";
        // console.log("contextRoot=", this.getContextRoot());
        if (!url.startsWith("http")) {
            url = "/" + this.getContextRoot() + url;
        }
        return fetch(url, {
            method     : 'post',
            headers    : {
                'Accept'      : 'application/json',
                'Content-Type': 'application/json'
            },
            body       : JSON.stringify(graphQLParams),
            credentials: 'include'
        }).then(function (response) {
            return response.text();
        }).then(function (responseBody) {
            try {
                return JSON.parse(responseBody);
            } catch (error) {
                return responseBody;
            }
        });
    }

}
